import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleChakraCrossProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/chacra_cross_01.jpg";
import galleryImg2 from "../../../images/products/chacra_cross_02.jpg";
import galleryImg3 from "../../../images/products/chacra_cross_03.jpg";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  }
];

export const SaleChakraCrossProduct = () => {
    const productName = "Karkötő kereszt medállal";

    const queryParams = new URLSearchParams(useLocation().search);  
    const userId = queryParams.get('userId');
    const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
    const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

    const navigate = useNavigate();

    const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
    }

    useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
    }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez a kifinomult és elegáns karkötő mind férfiak, mind nők számára tökéletes választás, hitük kifejezésére.</p>
        <p>A központi kereszt medál a hit és a megváltás szimbóluma, amely erőt és reményt ad viselőjének. A karkötő finom részletei és szimbolikus elemei egyedi megjelenést biztosítanak, és bármilyen öltözéket stílusosan kiegészítenek. </p>
        <p>Tökéletes ajándék szeretteinek vagy saját magának, hogy mindig közel érezhesse magát a hithez.</p>
        <p>Színe: sötétszürke</p>
        <p>A karkötő hossza: 18-19 cm</p>
        <p>Medál mérete: 18mm</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
