import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleLedCandleProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/led_candle_01.webp";
import galleryImg2 from "../../../images/products/led_candle_02.webp";
import galleryImg3 from "../../../images/products/led_candle_03.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  }
];

export const SaleLedCandleProduct = () => {
    const productName = "LED gyertya";

    const queryParams = new URLSearchParams(useLocation().search);  
    const userId = queryParams.get('userId');
    const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
    const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

    const navigate = useNavigate();

    const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
    }

    useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
    }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Emlékezzen szeretteire Mindenszentekkor ezekkel a LED gyertyákkal, amelyek méltó és tiszteletteljes megemlékezést biztosítanak. Az élethű lángok és a stílusos kivitel révén bármely emlékhelyen méltóságteljes hangulatot teremtenek.</p>
        <p>Biztonságosak és energiatakarékosak, hosszú élettartamuknak köszönhetően pedig ritkán kell cserélni őket. Ideális választás mindazok számára, akik szeretnének méltó módon megemlékezni elhunyt szeretteikről.</p>
        <p>Méretek: 5cm x 4cm</p>
        <p>Az elemeket tartalmazza!</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
